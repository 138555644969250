<template>
  <div class="flex overflow-hidden relative">
    <div class="py-8 pr-7 w-full" :class="sideModal === true ? 'mr-72' : ''">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold">Corpse List</h1>
        <button
          @click="addNewCorpse()"
          class="bg-primary py-4 px-7 rounded-xl shadow-lg flex items-center font-bold text-white"
        >
          <plus class="mr-5" />
          Add Admission
        </button>
      </div>
      <div class="" v-if="corpses.length !== 0">
        <addmission-table
          :allCorpse="corpses"
          :perPage="corpsePerPage"
          :currentPage="currentPage"
          :total="totalCorpse"
          @fetchPage="fetchAllCorpse"
          @hide="hideCorpse"
          @view="viewCorpse"
          @continue="continueCorpse"
        />
      </div>
      <div class="h-full w-full flex items-center justify-center" v-else>
        <Spinner />
      </div>
    </div>
    <view-addmission
      v-if="sideModal"
      :corpse="editCorpseDetail"
      @close="
        sideModal = false;
        editCorpseDetail = {};
        push = 1;
      "
      @edit="editThisCorpse"
    />
    <edit-corpse
      :corpse="corpse"
      @close="
        corpse = {};
        editCorpseModal = false;
      "
      @created="fetchAllCorpse"
      v-if="editCorpseModal"
    />
    <add-addmission
      v-if="overlay"
      :push="push"
      :corpseID="id"
      @close="
        overlay = false;
        editCorpseDetail = {};
        push = 1;
      "
      @created="fetchAllCorpse"
    />
  </div>
</template>

<script>
import { fetchcorpse } from "@/services/addmission";

export default {
  name: "AddmissionIndex",
  components: {
    plus: () => import("@/assets/icons/PlusIcon.vue"),
    AddmissionTable: () =>
      import("@/components/Addmission/AddmissionTable.vue"),
    AddAddmission: () =>
      import("@/components/Addmission/AddNewAddmissionModal.vue"),
    EditCorpse: () => import("@/components/Addmission/ChangeAddmissionModal"),
    ViewAddmission: () =>
      import("@/components/Addmission/EditAddmissionModal.vue"),
    Spinner: () => import("@/components/Base/Spinner.vue"),
  },
  data() {
    return {
      corpse: {},
      editCorpseModal: false,
      sideModal: false,
      sideModalData: {},
      overlay: false,
      corpses: [],
      totalCorpse: 0,
      corpsePerPage: 0,
      currentPage: 0,
      editCorpseDetail: {},
      submitting: false,
      id: "",
      push: 1,
    };
  },
  methods: {
    addNewCorpse() {
      this.overlay = true;
    },
    closeNewCorpse() {
      this.sideModal = false;
      this.overlay = false;
    },
    viewCorpse(corpse) {
      this.sideModal = true;
      this.editCorpseDetail = corpse;
    },
    editThisCorpse(corpse) {
      console.log(corpse);
      this.editCorpseModal = true;
      this.corpse = corpse;
    },
    continueCorpse(id) {
      this.id = id;
      this.push = 2;
      this.overlay = true;
    },
    hideCorpse(corpse) {
      console.log("Deleting Branch", corpse);
    },
    async fetchAllCorpse(page = 1) {
      this.sideModal = false;
      this.overlay = false;
      this.editCorpseDetail = {};
      this.push = 1;
      try {
        const response = await fetchcorpse(page);
        this.corpses = response.data.corpse.data;
        this.corpsePerPage = response.data.corpse.per_page;
        this.totalCorpse = response.data.corpse.total;
        this.currentPage = response.data.corpse.current_page;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchAllCorpse();
    console.log(this.$route.params.action);
    if (this.$route.params.action === "new") {
      this.overlay = true;
    }
  },
};
</script>
